$hk-color-white: #ffffff;
$hk-color-navy: #002a40;
$hk-color-gray: #f2f2f2;
$hk-color-dark-gray: #787872;
$hk-color-darker-gray: #e4e5e5;
$hk-color-border: #e4e5e5;
$hk-color-light-gray: #f2f5f7;

// typography color
$hk-color-text-black: #404041;
$hk-color-text-gray: #a4a4a4;
$hk-color-heading-1: #808080;

// status color
$hk-color-status-green: #38a169;
$hk-color-status-yellow: #ecc12a;
$hk-color-status-red: #ed2b2a;
$hk-color-status-orange: #fa8c16;
$hk-color-status-blue: #2587fb;

// color theme
$hk-color-primary-green: #007861;
$hk-color-secondary-green: #79c043;
$hk-color-secondary-yellow: #faedc2;
$hk-color-secondary-red: #fbd5d4;
