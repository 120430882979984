@import 'scss/variables/index';

.hk-tag-color {
  &-orange {
    color: $hk-color-status-orange;
    background: rgba(250, 140, 22, 0.1);
  }
  &-yellow {
    color: $hk-color-status-yellow;
    background: rgba(236, 193, 42, 0.2);
  }
  &-green {
    color: $hk-color-status-green;
    background: rgba(121, 192, 67, 0.2);
  }
  &-red {
    color: $hk-color-status-red;
    background: rgba(237, 43, 42, 0.2);
  }
  &-blue {
    color: $hk-color-status-blue;
    background: rgba(37, 135, 251, 0.2);
  }
  &-default {
    color: $hk-color-text-black;
    background: rgba(92, 92, 92, 0.2);
  }
}
