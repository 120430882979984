@import 'scss/variables/index';

.ant-btn.hk-button {
  font-size: $hk-font-size-sm;
  line-height: normal;
  box-shadow: none;
  padding: $spacing-md + 2;
  height: unset;
  border: unset;

  &.btn-full {
    width: 100%;
  }

  &.btn-black:not([disabled]) {
    background-color: $hk-color-navy;

    &:hover {
      background-color: $hk-color-navy;
      opacity: 0.8;
    }
  }

  &.btn-red {
    background-color: $hk-color-status-red;

    &:hover {
      color: white;
      opacity: 0.9;
    }
  }

  &.btn-green-dark {
    background-color: $hk-color-primary-green;

    &:hover {
      color: white;
      opacity: 0.9;
    }
  }

  &.btn-border {
    border: 1px solid $hk-color-secondary-green;
    background-color: unset;
    color: $hk-color-secondary-green;

    &-red {
      color: $hk-color-status-red;
      border: 1px solid $hk-color-status-red;

      &:hover {
        color: $hk-color-status-red;
        border: 1px solid $hk-color-status-red;
        opacity: 0.5;
      }
    }

    &-black {
      color: $hk-color-navy;
      border: 1px solid $hk-color-navy;

      &:hover {
        color: $hk-color-navy;
        border: 1px solid $hk-color-navy;
        opacity: 0.5;
      }
    }

    &-green-dark {
      color: $hk-color-primary-green;
      border: 1px solid $hk-color-primary-green;

      &:hover {
        color: $hk-color-primary-green;
        border: 1px solid $hk-color-primary-green;
        opacity: 0.5;
      }
    }
  }

  &.ant-btn-sm {
    font-size: $hk-font-size-xs + 2;
    padding: 6px 10px;
    height: unset;
  }
}
