.summary-card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column nowrap;
  border: 1px solid #e4e5e5;
  background-color: white;
  border-radius: 8px;
  width: fit-content;
  min-width: 360px;
  max-height: 104px;
  height: 104px;
  margin: 12px 0px;
  padding: 0 12px;
  .value {
    color: #404041;
    font-size: 32px;
    font-weight: 500;
    margin: 0px;
  }

  .label {
    margin: 0px;
    color: #787872;
    font-size: 12px;
    font-weight: 400;
  }
}
