.flex {
  display: flex;

  &-row {
    flex-direction: row;
  }

  &-column {
    flex-direction: column;
  }

  &.center {
    justify-content: center;
  }

  &.space-around {
    justify-content: space-around;
  }

  &.space-between {
    justify-content: space-between;
  }

  &.space-evenly {
    justify-content: space-evenly;
  }

  &.end {
    justify-content: end;
  }
}

.w {
  &-100 {
    width: 100%;
  }
}

.h {
  &-100 {
    height: 100%;
  }
}
