@import 'scss/utils/index';
@import 'scss/variables/index';

html {
  font-family:
    'Work Sans',
    ui-sans-serif,
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    'Helvetica Neue',
    Arial,
    'Noto Sans',
    sans-serif,
    'Apple Color Emoji',
    'Segoe UI Emoji',
    'Segoe UI Symbol',
    'Noto Color Emoji';
  font-size: 16px;

  body {
    margin: 0;
  }
}

::-webkit-scrollbar-button {
  display: none;
}

.hk-cms {
  min-height: 100vh;

  &-layout {
    height: max-content;

    .ant-layout-content {
      padding: $spacing-lg;
      background-color: $hk-color-white;
    }
  }

  // global style table
  .ant-table {
    background: unset;

    &-wrapper {
      margin-top: $spacing-lg;
      border: 1px solid $hk-color-border;
      border-radius: 8px;
      background: none;

      .ant-table-thead tr th.ant-table-cell {
        text-align: center;
      }

      .ant-table-tbody tr {
        td.ant-table-cell {
          font-size: 13px;
          font-weight: 400;
          line-height: normal;

          &.ant-table-cell-row-hover {
            background: unset;
          }
        }

        &:last-child td.ant-table-cell {
          border: unset;
        }
      }

      ::-webkit-scrollbar {
        width: 8px;
        background-color: $hk-color-darker-gray;
        position: absolute;
      }

      ::-webkit-scrollbar-thumb {
        background-color: #bbc9d4;
        border-radius: 20px;
      }

      .ant-table-title {
        text-align: left;
        padding: $spacing-md + 2 0 $spacing-md + 2 $spacing-lg;
        font-size: $hk-font-size-sm + 6;
        font-weight: 500;
      }

      .ant-pagination {
        margin: 0;
        padding: $spacing-md 34px;
        border-top: 1px solid $hk-color-darker-gray;
        justify-content: flex-start;
        flex-direction: row;

        .ant-pagination {
          &-item-active {
            background-color: $hk-color-primary-green;
            a {
              color: white;
            }
          }

          &-next:not(.ant-pagination-disabled),
          &-prev:not(.ant-pagination-disabled) {
            .anticon {
              svg {
                fill: $hk-color-primary-green;
              }
            }
          }
        }

        &-options {
          margin-left: auto;
        }
      }
    }
  }
}
