.hk-filters {
  .keyword-filter {
    width: 400px;
  }

  .dropdown-filter {
    min-width: 200px;
  }

  .price-range-filter {
    &:where(.ant-input-number) {
      width: 100%;
      height: 44px;
    }
    &:where(.ant-input-number-group-wrapper),
    &:where(.ant-input-number) .ant-input-number-input {
      height: 42px;
      width: 100%;
    }
  }
}
