@import 'scss/variables/index.scss';

aside.hk-sidebar {
  background: $hk-color-white;
  border-right: 1px solid $hk-color-border;
  padding: $spacing-lg $spacing-md;

  .hk-menu {
    .ant-menu {
      background: inherit;
      border-right: none;
      font-size: $hk-font-size-sm;
      border-inline-end: 0;

      &-submenu .ant-menu-submenu-title,
      &-item {
        margin: 0 0 $spacing-md 0;
        padding: $spacing-md;
        gap: $spacing-md;
      }

      &-submenu-selected .ant-menu-submenu-title {
        color: $hk-color-text-black;
      }

      &-item.ant-menu-item-selected,
      &-item.ant-menu-item-active,
      &-submenu.ant-menu-submenu-active {
        svg path {
          fill: white;
          transition: 1s;
        }
      }

      .ant-menu-submenu {
        background: unset;
      }

      .anticon {
        font-size: $hk-font-size-md;
      }

      .report-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .report-number {
          width: 18px;
          height: 18px;
          background-color: $hk-color-secondary-green;
          font-size: 12px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: white;
          border-radius: 40px;
        }
      }
    }
  }

  .hk-logout {
    margin-top: 120px;

    &-btn {
      width: fit-content;
      display: flex;
      font-size: $hk-font-size-sm;
      font-weight: 500;
      align-items: center;
      justify-content: center;
    }
  }
}
