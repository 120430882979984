@import 'scss/variables/index';

.hk-header {
  border-bottom: 1px solid $hk-color-border;
  height: 100px;

  &-logo {
    padding: 0;
    display: flex;
  }
}
