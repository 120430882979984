@import 'scss/variables/index';

.hk-color-status-yellow {
  color: $hk-color-status-yellow;
}
.hk-color-status-red {
  color: $hk-color-status-red;
}
.hk-color-green {
  color: $hk-color-status-green;
}
.hk-color-orange {
  color: $hk-color-status-orange;
}

.hk-color-gray {
  color: $hk-color-text-gray;
}

.hk-color-primary-green {
  color: $hk-color-primary-green;
}
